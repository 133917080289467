import React, { useEffect, useState } from 'react';

import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

export default function ComponentVehicle({ ...props }) {

    const { nodes, materials } = useGLTF('https://storage.googleapis.com/afrisafe-prod/model/main.gltf');
    const { gl, scene, camera } = useThree();

    const [text, setText] = useState('XX 00 XX 0000');
    const [cam, setCam] = useState(0);

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = ((bigint >> 16) & 255) / 255 * 100;
        const g = ((bigint >> 8) & 255) / 255 * 100;
        const b = (bigint & 255) / 255 * 100;
        return new THREE.Color((r / 100), (g / 100), (b / 100));
    }

    const createDynamicTexture = (text, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.save();
        context.fillStyle = 'black';
        context.font = '80px Arial';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.rotate(Math.PI);
        context.scale(-1, 1);
        context.fillText(text, (width / 2), -(height / 2));
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }

    function changeCamPostion() {
        if (cam === 1) {
            camera.position.set(-1.4178697056283958, 1.9152592536544777, 4.8016635730571275);
            camera.rotation.set(-0.4338667011255442, -0.42169289651146297, -0.18740976586215233);
        } else if (cam === 2) {
            camera.position.set(-3.862600058302635, 1.202717525740492, 2.9912794093417143);
            camera.rotation.set(-0.4761190898933732, -0.5780031520906488, -0.27462837865363265);
        } else {
            camera.position.set(-5, 5, 5);
            camera.rotation.set(-0.7853981633974481, -0.6154797086703871, -0.5235987755982987);
        }
    }

    const changePosition = (status, text, color) => {
        setCam(status);
        setText(text);
        materials['carPaint'].color = hexToRgb(color);
        materials['bikePaint'].color = hexToRgb(color);
    }

    const loadData = () => {
        materials['carPaint'].color = hexToRgb('#F5F5F5');
        window.changePos = changePosition;
    }

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    useEffect(() => {
        changeCamPostion();
    }, [cam]);

    return (
        <group {...props} dispose={null}>
            <group position={[-0.5, -1.2, -0.4]} rotation={[0, 0, 0]}>
                {
                    (cam === 0 || cam === 1) && <>
                        <mesh castShadow geometry={nodes.plateCar.geometry} material={materials['tireB']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.LableCar.geometry}>
                            <meshBasicMaterial map={createDynamicTexture(text, 624, 106)} />
                        </mesh>
                        <mesh castShadow geometry={nodes.carWindowA.geometry} material={materials['GlassWindow']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.carWindowB.geometry} material={materials['carWindow2']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['wheel-tireA'].geometry} material={materials['tireA']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['wheel-tireB'].geometry} material={materials['tireB']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['wheel-tireB_1'].geometry} material={materials['tireC']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['wheel-tireC'].geometry} material={materials['tireD']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['wheel-tireD'].geometry} material={materials['tireE']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.calD.geometry} material={materials['tireE']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot009.geometry} material={materials['Material.008']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot012.geometry} material={materials['tireB']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot011.geometry} material={materials['tireB']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot008.geometry} material={materials['Material.007']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot007.geometry} material={materials['Material.005']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot.geometry} material={materials['Material.002']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot001.geometry} material={materials['carPaint']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot002.geometry} material={materials['chrome']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot003.geometry} material={materials['Glass']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot004.geometry} material={materials['PLASTIC']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot005.geometry} material={materials['LED PHARE']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.Capot006.geometry} material={materials['matB']}>
                        </mesh>
                        <mesh castShadow geometry={nodes.int.geometry} material={materials['matA']}>
                        </mesh>
                    </>
                }
                {
                    (cam === 0 || cam === 2) && <>
                        <mesh castShadow geometry={nodes['bike-Material007'].geometry} material={materials['Material.007']}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-bikePaint'].geometry} material={materials.bikePaint}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-bike_black'].geometry} material={materials.bike_black}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-gray'].geometry} material={materials.gray}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-headlight'].geometry} material={materials.headlight}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-middle_tyre'].geometry} material={materials.middle_tyre}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-tyre_cover'].geometry} material={materials.tyre_cover}>
                        </mesh>
                        <mesh castShadow geometry={nodes['bike-tyre_rubber'].geometry} material={materials.tyre_rubber}>
                        </mesh>
                    </>
                }
            </group>
        </group>
    )
}
