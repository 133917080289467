import React, { Suspense } from 'react';

import { Canvas } from '@react-three/fiber';
import { Environment, ContactShadows } from '@react-three/drei';
import ComponentVehicle from './components/ComponentVehicle';

export default function PageMain() {

    return (
        <div className="w-screen h-screen overflow-hidden relative">
            <Suspense fallback={null}>
                <Canvas shadows dpr={[1, 2]} camera={{ position: [-5, 5, 5], fov: 50, near: 0.001 }} onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}>
                    <ambientLight intensity={2} />
                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={6} castShadow shadow-mapSize={[2048, 2048]} />
                    <spotLight position={[-5, 5, -1.5]} angle={0.2} penumbra={1} intensity={4} castShadow shadow-mapSize={[1024, 1024]} />
                    <Suspense fallback={null}>
                        <ComponentVehicle />
                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                        <Environment background={false} preset="warehouse" />
                    </Suspense>
                </Canvas>
            </Suspense>
        </div>
    )
}