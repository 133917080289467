import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PageMain from './pages/PageMain';

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageMain />} />
                </Routes>
            </Router>
        </>
    )
}